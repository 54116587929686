import React, { useMemo } from "react"
import { graphql, PageProps } from "gatsby"
import { AusbildungPageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import ServicePageLayout from "../layouts/service-page-layout"

const Ausbildung: React.FC<PageProps<AusbildungPageProps>> = ({ data }) => {
  const {
    banner,
    ausbildungContent,
    ausbildungContentTwo,
    ausbildungAcc,
    trainersTitle,
    trainersKutiaSelector,
  } = data?.PAGE_CONTENT?.ausbildungOptions

  const accordions = useMemo(() => {
    return ausbildungAcc?.map((acc, index) => {
      return {
        id: String(index),
        title: acc.title,
        description: acc.description,
      }
    })
  }, [JSON.stringify(ausbildungAcc)])

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <ServicePageLayout
        banner={banner}
        pageTitle={data?.PAGE_METADATA?.title}
        content={ausbildungContent}
        accordions={accordions}
        trainersDesc={ausbildungContentTwo}
        trainersTitle={trainersTitle}
        trainers={trainersKutiaSelector as any}
      />
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      ausbildungOptions {
        banner {
          altText
          sizes
          srcSet
          sourceUrl
        }
        ausbildungContent
        ausbildungContentTwo
        ausbildungAcc {
          description
          title
        }
        trainersTitle
        trainersKutiaSelector {
          name
          link
          description
          image {
            altText
            sizes
            srcSet
            sourceUrl
          }
        }
      }
    }
  }
`

export default Ausbildung
