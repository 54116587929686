import React from "react"
import "./services-page-layout.scss"
import PageBanner from "../components/shared/page-banner"
import Accordion from "../components/shared/accordion"
import { ServicePageLayoutProps } from "../types/pages"
import { AccordionProps } from "../types/shared-singulars"
import Img from "../components/shared/img"
import SliderItem from "../components/shared/slider-item"

const ServicePageLayout: React.FC<ServicePageLayoutProps> = ({
  banner,
  pageTitle,
  content,
  accordions,
  trainersDesc,
  trainersTitle,
  trainers,
}) => {

  return (
    <>
      <PageBanner>
        <h1
          className="page-banner__title"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-easing="ease-out"
        >
          {pageTitle}
        </h1>
        <div className="page-banner__image">
          <Img imgProps={banner} delay="200" />
        </div>
        <div
          className="page-banner__description"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-easing="ease-out"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </PageBanner>
      <div className="accordion--wrapper">
        {accordions &&
          accordions.map((accordion: AccordionProps, accIndex: number) => (
            <Accordion
              key={accIndex}
              id={accIndex}
              titileAccordions={accordion.title}
              descriptionAccordions={accordion.description}
            />
          ))}
      </div>
      {trainers && trainersTitle && (
        <>
          <div
            className="trainers__grid__desc container--xl"
            dangerouslySetInnerHTML={{ __html: trainersDesc ?? "" }}
          />
          <h3 className="trainers__grid--title container--xl">
            {trainersTitle}
          </h3>
          <div className="trainers__grid container--xl">
            {trainers?.map((item, sliderIndex) => (
              <SliderItem
                key={sliderIndex}
                parent="accordion"
                uri={item.link}
                imgProps={item.image}
                title={item.name}
              >
                <p className="slider-item__description">{item.description}</p>
              </SliderItem>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default ServicePageLayout
